import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.js";

import { appURLs } from "@/app/app-urls.mjs";
import { subscriptionRefs } from "@/feature-subscriber/utils/subscription.refs.mjs";
import ArrowRightIcon from "@/inline-assets/arrow-right2.svg";
import { AuthFailure } from "@/shared/AuthFailure.jsx";
import { AuthSuccess } from "@/shared/AuthSuccess.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  subscriberCta: () => css`
    border: 1px solid var(--shade3-25);
    border-radius: var(--br-lg);

    padding: var(--sp-6);

    max-width: 760px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--sp-5);
    align-items: center;

    .premium {
      color: var(--subscriber);
    }

    /* TODO: this should be a clutch variant */
    a[data-emphasis="high"] {
      & {
        --bg: var(--shade0) !important;
        --border: transparent !important;
        color: var(--shade7) !important;
      }

      &:hover,
      &:focus {
        --bg: hsla(0deg 0% 100% / 0.85) !important;
      }
    }

    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--sp-4);

      width: 100%;

      li {
        border-radius: var(--br);
        max-width: 225px;
        width: 100%;
        aspect-ratio: 1.5;

        background-position: center;
        background-size: contain;

        padding: var(--sp-2);
      }

      @container result-container (width < 630px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  `,
};

const AUTH_TABS = {
  valid: {
    title: ["common:account.redirect.success.title", "Authorization Granted"],
    subtitle: [
      "common:account.redirect.success.subtitle",
      "You're logged in now! Close this page, open the app or website, and Blitz it up.",
    ],
  },
  invalid: {
    title: ["", "Authentication Denied"],
    subtitle: ["", "This auth code is invalid."],
  },
} as const;

const EMAIL_TABS = {
  verify: {
    title: ["", "Verification Needed"],
    subtitle: ["", "A verification email is on it's way to {{email}}."],
  },
  new: {
    title: ["", "New Email Confirmed"],
    subtitle: [
      "",
      "Please confirm your old email, or if you've done that you can head back to the app.",
    ],
  },
  old: {
    title: ["", "Current (Old) Email Confirmed"],
    subtitle: [
      "",
      "Please confirm your new email, or if you've done that, you can head back to the app.",
    ],
  },
  invalid: {
    title: ["", "Email Verification Failed"],
    subtitle: ["", "Verification has failed, please try again."],
  },
} as const;

function AuthResult() {
  const {
    searchParams,
    parameters: [tab1, tab2],
  } = useRoute();
  const { activeSubscription } = useSnapshot(subscriptionRefs);

  if (tab1 === "auth" && tab2 === "valid") {
    return (
      <AuthSuccess {...AUTH_TABS.valid}>
        {!activeSubscription && <NonSubscriberUpsell />}
      </AuthSuccess>
    );
  }

  if (tab1 === "email" && EMAIL_TABS[tab2]) {
    const email = searchParams.get("email");
    const code = searchParams.get("code");
    if (tab2 === "verify" && email && code)
      return (
        <AuthFailure
          title={EMAIL_TABS.verify.title}
          subtitle={[
            ...EMAIL_TABS.verify.subtitle,
            { email: decodeURI(email) },
          ]}
        >
          <div
            style={{ maxWidth: "360px" }}
            className="w-full pad-3 bg-shade7 shade1"
          >
            {decodeURI(code)}
          </div>
        </AuthFailure>
      );

    return <AuthSuccess {...EMAIL_TABS[tab2]} />;
  }

  return <AuthFailure {...AUTH_TABS.invalid} />;
}

const PREMIUM_SELLING_POINT = [
  {
    img: `${appURLs.CDN}/blitz/blitz-email/auth/success/net_lp.webp`,
    label: [
      "common:account.redirect.subscribe.addFree",
      "Enjoy Blitz Without Ads",
    ],
  },
  {
    img: `${appURLs.CDN}/blitz/blitz-email/auth/success/cs_overlay.webp`,
    label: [
      "common:account.redirect.subscribe.overlays",
      "Enable Premium Overlays",
    ],
  },
  {
    img: `${appURLs.CDN}/blitz/blitz-email/auth/success/support_overlay.webp`,
    label: [
      "common:account.redirect.subscribe.proBuilds",
      "Unlock Pro Builds+",
    ],
  },
] as const;
function NonSubscriberUpsell() {
  const { t } = useTranslation();

  return (
    <div className={Styled.subscriberCta()}>
      <h2 className="type-body2 shade1 text-center">
        <Trans i18nKey="common:account.redirect.subscribe.title">
          Upgrade to <strong className="premium">Premium</strong> today and rank
          up faster with exclusive premium features.
        </Trans>
      </h2>
      <ul className="type-callout shade0">
        {PREMIUM_SELLING_POINT.map(({ img, label }, i) => (
          <li key={i} style={{ backgroundImage: `url(${img})` }}>
            <p>{t(...label)}</p>
          </li>
        ))}
      </ul>
      <Button
        emphasis="high"
        block
        iconRight={<ArrowRightIcon />}
        href="/premium"
      >
        {t("common:subscriber.getSubscriber", "Get Premium")}
      </Button>
    </div>
  );
}

AuthResult.showAppNavigation = false;

export const meta = () => ({
  title: ["common:account.redirect.title", "Blitz Auth"],
  description: [null, ""],
});

export default AuthResult;
